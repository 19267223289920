<template>
  <div class="admin-access-codes v-step-access-codes-section">
    <div class="title">
      <h1>{{ $t('admin.access-codes.title') }}</h1>
    </div>
    <div class="content col-all-12">
      <div class="flexbox">
        <div class="boxes col-smpc-6 col-lgpc-6 col-mdpc-12">
          <ul class="ul-boxes col-pc-12">
            <li v-for="accessCode in accessCodes" :key="accessCode.id" class="access-code-box col-mobile-12 col-pc-12">
              <div class="li-content col-all-12">
                <div class="name col-pc-12">
                  <div class="text col-pc-12 col-mobile-12 col-f">
                    <div
                      class="copy-text col-all-12 v-step-copy-access-code"
                      :uk-tooltip="'title:' + $t('admin.access-codes.click-to-copy') + ';' + 'pos: bottom;'"
                      @click="copyAcessCode(accessCode.token)"
                    >
                      <h2 class="col-pc-auto col-mobile-auto">{{ accessCode.token }}</h2>
                      <i class="fas fa-copy copy-icon col-pc-2 col-mobile-2"></i>
                    </div>
                  </div>
                  <div class="permissions col-pc-12 col-mobile-12 col-f v-step-access-code-permissions-preview">
                    <div v-for="perm in accessCode.permissions" :key="perm.name" class="permission-badge">
                      <span class="uk-badge">{{ $t('admin.access-codes.' + perm.name) }}</span>
                    </div>
                  </div>
                </div>
                <div class="buttons col-pc-auto col-mobile-12 v-step-manage-access-code">
                  <div class="code-settings col-pc-12 col-mobile-6">
                    <button
                      class="warning-full col-mobile-6"
                      @click="
                        accessCodeSettingOpenned === accessCode.id
                          ? (accessCodeSettingOpenned = '')
                          : (accessCodeSettingOpenned = accessCode.id)
                        assignAccessCode(accessCode)
                      "
                      :class="{ active: accessCodeSettingOpenned === accessCode.id }"
                    >
                      <i class="fas fa-cog"></i>
                    </button>
                  </div>
                  <div class="code-kick col-pc-12 col-mobile-6">
                    <button
                      class="danger-full col-mobile-6"
                      :class="{
                        spinner: accessCodeSettingOpenned === accessCode.id && currentAccessCode.isDeleteLoading
                      }"
                      :disabled="currentAccessCode.isDeleteLoading"
                      @click="deleteAccessCode(accessCode.id)"
                    >
                      <i class="fas fa-times"></i>
                    </button>
                  </div>
                </div>
              </div>
              <div class="access-code-settings-dropdown" v-if="accessCodeSettingOpenned === accessCode.id">
                <div class="column">
                  <div class="input-container col-pc-8 col-mobile-10">
                    <div class="label-box">
                      <label for="code-input">{{ $t('admin.access-codes.code') }}</label>
                    </div>

                    <div class="col-pc-12 col-mobile-12 input-box">
                      <CustomInput
                        name="code-input"
                        style="height: 40px;"
                        class="custom-input custom-height primary col-pc-12 col-mobile-12"
                        inputClasses="box col-pc-12 col-mobile-12"
                        type="text"
                        :placeholder="$t('admin.access-codes.code_placeholder')"
                        v-model="$v.currentAccessCode.token.$model"
                        :model="$v.currentAccessCode.token"
                        :error="fieldsErrors.currentAccessCode"
                        :onlyBooleanError="true"
                      ></CustomInput>
                      <button
                        class="primary-full generate-button"
                        style="height:40px;"
                        @click="rollToken(false, currentAccessCode)"
                      >
                        <i class="fas fa-sync"></i>
                      </button>
                    </div>
                  </div>
                </div>
                <div class="column">
                  <div class="input-container col-pc-8 col-mobile-10">
                    <div class="label-box">
                      <label for="date-input">{{ $t('admin.access-codes.expiration') }}</label>
                      <i
                        class="fas fa-question-circle tooltip"
                        :uk-tooltip="'title:' + $t('admin.access-codes.expiration_tooltip') + ';'"
                      ></i>
                    </div>

                    <div class="input-box">
                      <VueDateTimePicker
                        id="current-access-code-date"
                        :label="$t('admin.access-codes.select-expiration-date')"
                        inputClasses="box"
                        :format="moment.locale() === 'en' ? 'YYYY-MM-DD h:mm A' : 'YYYY-MM-DD HH:mm'"
                        outputFormat="ISO"
                        formatted="LLL"
                        v-model="$v.currentAccessCode.expiresAt.$model"
                      ></VueDateTimePicker>
                    </div>
                  </div>
                </div>
                <div class="column">
                  <div class="input-container col-pc-8 col-mobile-10">
                    <div class="label-box">
                      <label for="use-left">{{ $t('admin.access-codes.use-left') }}</label>
                      <i
                        class="fas fa-question-circle tooltip"
                        :uk-tooltip="'title:' + $t('admin.access-codes.use-left_tooltip') + ';'"
                      ></i>
                    </div>

                    <div class="input-box">
                      <CustomInput
                        name="use-left"
                        class="custom-input primary col-pc-12 col-mdpc-4 col-mobile-12"
                        inputClasses="box col-pc-12 col-mobile-12"
                        type="number"
                        :placeholder="$t('admin.access-codes.use-left_placeholder')"
                        v-model.number="$v.currentAccessCode.useLeft.$model"
                        :model="$v.currentAccessCode.useLeft"
                        :min="0"
                      ></CustomInput>
                    </div>
                  </div>
                </div>
                <div class="column">
                  <ol>
                    <li>
                      <p>{{ $t('admin.users.dispatch') }}</p>
                      <toggle-button
                        id="admin-users-dispatch"
                        v-model="currentAccessCode.booleanPermissions.dispatch"
                        :labels="{
                          checked: $t('panic-buttons-dispatch_component.on'),
                          unchecked: $t('panic-buttons-dispatch_component.off')
                        }"
                      ></toggle-button>
                    </li>
                    <li>
                      <p>{{ $t('admin.users.police') }}</p>
                      <toggle-button
                        id="admin-users-police"
                        v-model="currentAccessCode.booleanPermissions.police"
                        :labels="{
                          checked: $t('panic-buttons-dispatch_component.on'),
                          unchecked: $t('panic-buttons-dispatch_component.off')
                        }"
                      ></toggle-button>
                    </li>
                    <li>
                      <p>{{ $t('admin.users.civilian') }}</p>
                      <toggle-button
                        id="admin-users-civilian"
                        v-model="currentAccessCode.booleanPermissions.civilian"
                        :labels="{
                          checked: $t('panic-buttons-dispatch_component.on'),
                          unchecked: $t('panic-buttons-dispatch_component.off')
                        }"
                      ></toggle-button>
                    </li>
                  </ol>
                </div>
                <div class="column">
                  <ol>
                    <li class="auto-accept toggle">
                      <p>{{ $t('admin.access-codes.auto-accept') }}</p>
                      <div class="toggle-box">
                        <toggle-button
                          id="admin-access-codes-auto-accept"
                          v-model="currentAccessCode.booleanPermissions.accepted"
                          :labels="{
                            checked: $t('panic-buttons-dispatch_component.on'),
                            unchecked: $t('panic-buttons-dispatch_component.off')
                          }"
                          @change="rollToken(true, currentAccessCode)"
                        ></toggle-button>
                        <i
                          class="fas fa-question-circle tooltip"
                          :uk-tooltip="'title:' + $t('admin.access-codes.auto-accept_tooltip') + ';'"
                        ></i>
                      </div>
                    </li>
                    <li class="administrator toggle">
                      <p>{{ $t('admin.access-codes.admin') }}</p>
                      <div class="toggle-box">
                        <toggle-button
                          id="admin-access-codes-admin"
                          v-model="currentAccessCode.booleanPermissions.admin"
                          :labels="{
                            checked: $t('panic-buttons-dispatch_component.on'),
                            unchecked: $t('panic-buttons-dispatch_component.off')
                          }"
                          @change="rollToken(true, currentAccessCode)"
                        ></toggle-button>
                        <i
                          class="fas fa-question-circle tooltip"
                          :uk-tooltip="'title:' + $t('admin.access-codes.admin_tooltip') + ';'"
                        ></i>
                      </div>
                    </li>
                  </ol>
                </div>
                <div class="column">
                  <button
                    class="primary-full col-pc-8 col-mobile-10"
                    :class="{ spinner: currentAccessCode.isLoading }"
                    :disabled="currentAccessCode.isLoading || $v.currentAccessCode.$invalid"
                    @click="saveAccessCode"
                  >
                    {{ $t('actions.save') }}
                  </button>
                  <!-- <button
                    class="primary col-pc-2 col-mobile-10"
                    style="margin-left: 10px;"
                    @click="assignAccessCode(accessCode)"
                  >{{ $t('actions.clear') }}</button>-->
                </div>
              </div>
            </li>
            <li class="add access-code-box col-mobile-12 col-pc-12 v-step-create-access-code">
              <div class="li-content col-all-12">
                <div class="icon col-pc-auto col-mobile-auto col-f">
                  <i class="fas fa-plus"></i>
                </div>
                <div class="li-text col-pc-12 col-mobile-12 col-f">
                  <h3>{{ $t('admin.access-codes.new-access-code') }}</h3>
                </div>
                <div class="empty col-pc-displaynone col-mobile-displaynone"></div>
              </div>
              <div class="access-code-settings-dropdown">
                <div class="column">
                  <div class="input-container col-pc-8 col-mobile-10">
                    <div class="label-box">
                      <label for="add-code-input">{{ $t('admin.access-codes.code') }}</label>
                    </div>

                    <div class="col-pc-12 input-box">
                      <CustomInput
                        name="add-code-input"
                        style="height: 40px;"
                        class="custom-input custom-height primary col-pc-12 col-mobile-12"
                        inputClasses="box col-pc-12 col-mobile-12"
                        type="text"
                        :placeholder="$t('admin.access-codes.code_placeholder')"
                        v-model="$v.newAccessCode.token.$model"
                        :model="$v.newAccessCode.token"
                        :error="fieldsErrors.newAccessCodeToken"
                        :onlyBooleanError="true"
                      ></CustomInput>
                      <button
                        class="primary-full generate-button custom-height"
                        style="height: 40px;"
                        @click="rollToken(false, newAccessCode)"
                      >
                        <i class="fas fa-sync"></i>
                      </button>
                    </div>
                  </div>
                </div>
                <div class="column">
                  <div class="input-container col-pc-8 col-mobile-10">
                    <div class="label-box">
                      <label for="admin-add-access-code-date-input">{{ $t('admin.access-codes.expiration') }}</label>
                      <i
                        class="fas fa-question-circle tooltip"
                        :uk-tooltip="'title:' + $t('admin.access-codes.expiration_tooltip') + ';'"
                      ></i>
                    </div>

                    <div class="input-box">
                      <VueDateTimePicker
                        id="new-access-code-date"
                        :label="$t('admin.access-codes.select-expiration-date')"
                        inputClasses="box"
                        :format="moment.locale() === 'en' ? 'YYYY-MM-DD h:mm A' : 'YYYY-MM-DD HH:mm'"
                        formatted="LLL"
                        outputFormat="ISO"
                        v-model="$v.newAccessCode.expiresAt.$model"
                      ></VueDateTimePicker>
                    </div>
                  </div>
                </div>
                <div class="column">
                  <div class="input-container col-pc-8 col-mobile-10">
                    <div class="label-box">
                      <label for="admin-add-access-code-use-left">{{ $t('admin.access-codes.use-left') }}</label>
                      <i
                        class="fas fa-question-circle tooltip"
                        :uk-tooltip="'title:' + $t('admin.access-codes.use-left_tooltip') + ';'"
                      ></i>
                    </div>

                    <div class="input-box">
                      <CustomInput
                        name="admin-add-access-code-use-left"
                        class="custom-input primary col-pc-12 col-mdpc-4 col-mobile-12"
                        inputClasses="box col-pc-12 col-mobile-12"
                        type="number"
                        :placeholder="$t('admin.access-codes.use-left_placeholder')"
                        v-model.number="$v.newAccessCode.useLeft.$model"
                        :model="$v.newAccessCode.useLeft"
                        :min="0"
                      ></CustomInput>
                    </div>
                  </div>
                </div>
                <div class="column">
                  <ol>
                    <li>
                      <p>{{ $t('admin.users.dispatch') }}</p>
                      <toggle-button
                        id="admin-add-access-code-dispatch"
                        v-model="newAccessCode.booleanPermissions.dispatch"
                        :labels="{
                          checked: $t('panic-buttons-dispatch_component.on'),
                          unchecked: $t('panic-buttons-dispatch_component.off')
                        }"
                      ></toggle-button>
                    </li>
                    <li>
                      <p>{{ $t('admin.users.police') }}</p>
                      <toggle-button
                        id="admin-add-access-code-police"
                        v-model="newAccessCode.booleanPermissions.police"
                        :labels="{
                          checked: $t('panic-buttons-dispatch_component.on'),
                          unchecked: $t('panic-buttons-dispatch_component.off')
                        }"
                      ></toggle-button>
                    </li>
                    <li>
                      <p>{{ $t('admin.users.civilian') }}</p>
                      <toggle-button
                        id="admin-add-access-code-civilian"
                        v-model="newAccessCode.booleanPermissions.civilian"
                        :labels="{
                          checked: $t('panic-buttons-dispatch_component.on'),
                          unchecked: $t('panic-buttons-dispatch_component.off')
                        }"
                      ></toggle-button>
                    </li>
                  </ol>
                </div>
                <div class="column">
                  <ol>
                    <li class="auto-accept toggle">
                      <p>{{ $t('admin.access-codes.auto-accept') }}</p>
                      <div class="toggle-box">
                        <toggle-button
                          id="admin-add-access-code-auto-accept"
                          v-model="newAccessCode.booleanPermissions.accepted"
                          :labels="{
                            checked: $t('panic-buttons-dispatch_component.on'),
                            unchecked: $t('panic-buttons-dispatch_component.off')
                          }"
                          @change="rollToken(true, newAccessCode)"
                        ></toggle-button>
                        <i
                          class="fas fa-question-circle tooltip"
                          :uk-tooltip="'title:' + $t('admin.access-codes.auto-accept_tooltip') + ';'"
                        ></i>
                      </div>
                    </li>
                    <li class="administrator toggle">
                      <p>{{ $t('admin.access-codes.admin') }}</p>
                      <div class="toggle-box">
                        <toggle-button
                          id="admin-add-access-code-admin"
                          v-model="newAccessCode.booleanPermissions.admin"
                          :labels="{
                            checked: $t('panic-buttons-dispatch_component.on'),
                            unchecked: $t('panic-buttons-dispatch_component.off')
                          }"
                          @change="rollToken(true, newAccessCode)"
                        ></toggle-button>
                        <i
                          class="fas fa-question-circle tooltip"
                          :uk-tooltip="'title:' + $t('admin.access-codes.admin_tooltip') + ';'"
                        ></i>
                      </div>
                    </li>
                  </ol>
                </div>
                <div class="column">
                  <button
                    class="primary-full col-pc-8 col-mobile-10"
                    :class="{ spinner: newAccessCode.isLoading }"
                    :disabled="newAccessCode.isLoading"
                    @click="addAccessCode"
                  >
                    {{ $t('admin.access-codes.add-code') }}
                  </button>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>
<style lang="less" scoped src="@/assets/less/dashboards/user-interfaces.less"></style>

<script>
import { mapGetters } from 'vuex'
import shortid from 'shortid'
import CustomInput from '@/components/CustomInput'
import validationMessage from '@/validationMapping'
import { required } from 'vuelidate/lib/validators'

export default {
  name: 'general',
  components: {
    CustomInput
  },
  data() {
    return {
      fieldsErrors: {},
      accessCodeSettingOpenned: '',
      accessCodes: [],
      currentAccessCode: {
        token: '',
        expiresAt: null,
        useLeft: null,
        booleanPermissions: {
          dispatch: false,
          police: false,
          civilian: false,
          accepted: false,
          admin: false
        },
        isLoading: false,
        isDeleteLoading: false
      },
      newAccessCode: {
        token: '',
        expiresAt: null,
        useLeft: null,
        booleanPermissions: {
          dispatch: false,
          police: false,
          civilian: false,
          accepted: false,
          admin: false
        },
        isLoading: false
      },
      intitialNewAccessCode: null
    }
  },
  validations: {
    currentAccessCode: {
      token: {
        required
      },
      expiresAt: {},
      useLeft: {}
    },
    newAccessCode: {
      token: {
        required
      },
      expiresAt: {},
      useLeft: {}
    }
  },
  apollo: {
    accessCodes: {
      query: require('@/graphql/admin/fetchJoinToken.gql'),
      variables() {
        return {
          communityId: this.$store.state.selection.community.community.id
        }
      },
      update: data => data.community.joinTokens,
      fetchPolicy: 'no-cache',
      subscribeToMore: {
        document: require('@/graphql/admin/subscribeToJoinToken.gql'),
        variables() {
          return {
            communityId: this.$store.state.selection.community.community.id
          }
        },
        updateQuery(previousResult, { subscriptionData }) {
          const joinToken = subscriptionData.data.communityJoinToken

          if (joinToken.mutation === 'CREATED') {
            return this.addJoinTokenToArray(previousResult.community.joinTokens, joinToken.node)
          }

          if (joinToken.mutation === 'UPDATED') {
            return this.updateJoinTokenInArray(previousResult.community.joinTokens, joinToken.node)
          }

          if (joinToken.mutation === 'DELETED') {
            return this.removeJoinTokenFromArray(previousResult.community.joinTokens, joinToken.previousValues.id)
          }
        }
      }
    }
  },
  methods: {
    ...mapGetters('auth', ['checkPermissions']),
    addJoinTokenToArray(theArray, toAdd) {
      return {
        community: {
          __typename: 'Community',
          joinTokens: [...theArray, toAdd]
        }
      }
    },
    updateJoinTokenInArray(theArray, toUpdate) {
      return {
        community: {
          __typename: 'Community',
          joinTokens: theArray.map(theJoinToken => (theJoinToken.id === toUpdate.id ? toUpdate : theJoinToken))
        }
      }
    },
    removeJoinTokenFromArray(theArray, toRemove) {
      return {
        community: {
          __typename: 'Community',
          joinTokens: theArray.filter(theJoinToken => theJoinToken.id !== toRemove)
        }
      }
    },
    assignAccessCode(accessCode) {
      this.currentAccessCode = Object.assign({}, this.currentAccessCode, accessCode)

      this.currentAccessCode.booleanPermissions.dispatch = this.checkPermissions()(
        accessCode.permissionsValue,
        'dispatch'
      )
      this.currentAccessCode.booleanPermissions.police = this.checkPermissions()(accessCode.permissionsValue, 'police')
      this.currentAccessCode.booleanPermissions.civilian = this.checkPermissions()(
        accessCode.permissionsValue,
        'civilian'
      )
      this.currentAccessCode.booleanPermissions.accepted = this.checkPermissions()(
        accessCode.permissionsValue,
        'accepted'
      )
      this.currentAccessCode.booleanPermissions.admin = this.checkPermissions()(accessCode.permissionsValue, 'admin')
    },
    rollToken(skipIfValid, accessToken) {
      if (skipIfValid && shortid.isValid(accessToken.token)) return

      accessToken.token = shortid.generate()
    },
    saveAccessCode() {
      this.currentAccessCode.isLoading = true

      if (this.$v.currentAccessCode.$invalid) {
        this.$store.dispatch('errorManager/showError', { error: 'FIX_ERRORS' })
        this.fieldsErrors = {
          currentAccessCode: validationMessage(this.$v.currentAccessCode.token)
        }
        this.currentAccessCode.isLoading = false

        return
      }

      const updatePromise = new Promise((resolve, reject) => {
        this.$store
          .dispatch('admin/updateAccessCode', {
            communityJoinTokenId: this.currentAccessCode.id,
            data: {
              token: this.currentAccessCode.token,
              expiresAt: this.currentAccessCode.expiresAt,
              useLeft: this.currentAccessCode.useLeft !== '' ? this.currentAccessCode.useLeft : null
            }
          })
          .then(() => resolve())
          .catch(error => reject(error))
      })

      // Update permissions
      const toAdd = []
      const toRemove = []
      Object.keys(this.currentAccessCode.booleanPermissions).forEach(permissionName => {
        if (this.currentAccessCode.booleanPermissions[permissionName]) {
          toAdd.push({ name: permissionName })
        } else {
          if (this.checkPermissions()(this.currentAccessCode.permissionsValue, permissionName)) {
            toRemove.push({ name: permissionName })
          }
        }
      })

      const toAddPromise = new Promise((resolve, reject) => {
        if (toAdd.length > 0) {
          this.$store
            .dispatch('admin/updateAccessCode', {
              communityJoinTokenId: this.currentAccessCode.id,
              data: {
                permissions: {
                  connect: toAdd
                }
              }
            })
            .then(() => resolve())
            .catch(error => reject(error))
        } else {
          resolve()
        }
      })

      const toRemovePromise = new Promise((resolve, reject) => {
        if (toRemove.length > 0) {
          this.$store
            .dispatch('admin/updateAccessCode', {
              communityJoinTokenId: this.currentAccessCode.id,
              data: {
                permissions: {
                  disconnect: toRemove
                }
              }
            })
            .then(() => resolve())
            .catch(error => reject(error))
        } else {
          resolve()
        }
      })

      Promise.all([updatePromise, toAddPromise, toRemovePromise])
        .then(() => {
          this.accessCodeSettingOpenned = ''
          this.currentAccessCode.isLoading = false

          this.$notify({
            type: 'success',
            title: this.$t('notifications.save.title'),
            text: this.$t('notifications.save.text')
          })
        })
        .catch(error => {
          this.currentAccessCode.isLoading = false
          this.$store.dispatch('errorManager/showError', { error: error })
        })
    },
    addAccessCode() {
      this.newAccessCode.isLoading = true

      if (this.$v.newAccessCode.$invalid) {
        this.$store.dispatch('errorManager/showError', { error: 'FIX_ERRORS' })
        this.fieldsErrors = {
          newAccessCodeToken: validationMessage(this.$v.newAccessCode.token)
        }
        this.newAccessCode.isLoading = false

        return
      }

      // Update permissions
      const toAdd = []
      Object.keys(this.newAccessCode.booleanPermissions).forEach(permissionName => {
        if (this.newAccessCode.booleanPermissions[permissionName]) {
          toAdd.push({ name: permissionName })
        }
      })

      this.$store
        .dispatch('admin/createAccessCode', {
          token: this.newAccessCode.token,
          expiresAt: this.newAccessCode.expiresAt,
          useLeft: this.newAccessCode.useLeft !== '' ? this.newAccessCode.useLeft : null,
          permissions: {
            connect: toAdd
          }
        })
        .then(() => {
          this.newAccessCode = Object.assign({}, this.intitialNewAccessCode)

          this.$notify({
            type: 'success',
            title: this.$t('notifications.save.title'),
            text: this.$t('notifications.save.text')
          })
        })
        .catch(error => {
          this.currentAccessCode.isLoading = false
          this.$store.dispatch('errorManager/showError', { error: error })
        })
    },
    deleteAccessCode(communityJoinTokenId) {
      this.$swal({
        title: this.$t('admin.access-codes.delete-dialog.are-you-sure'),
        text: this.$t('admin.access-codes.delete-dialog.you-wont-be-able-to-revert'),
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: this.$t('actions.delete'),
        cancelButtonText: this.$t('actions.cancel')
      }).then(result => {
        if (result.value) {
          this.currentAccessCode.isDeleteLoading = true

          this.$store
            .dispatch('admin/deleteAccessCode', communityJoinTokenId)
            .then(() => {
              this.currentAccessCode.isDeleteLoading = false
            })
            .catch(error => {
              this.currentAccessCode.isLoading = false
              this.$store.dispatch('errorManager/showError', { error: error })
            })
        }
      })
    },
    copyAcessCode(token) {
      this.$copyText(token).then(() => {
        this.$notify({
          type: 'success',
          title: this.$t('notifications.copy.title'),
          text: this.$t('notifications.copy.text', { text: token })
        })
      })
    }
  },
  mounted() {
    this.intitialNewAccessCode = Object.assign({}, this.newAccessCode)
  }
}
</script>
